@import "../../../../../../../../assets/scss/variables";
@import "../../../../../../../../assets/scss/mixin";

.crossList {
  &_title {
    @include text(1rem, layout-grey-90, medium);
    padding: 0 0 1.5rem;
  }
  .form {
    min-width: 70vh;
    &_group {
      margin: 0 !important;
    }
  }
}

.search {
  margin: 1.5rem 0 0;
}

.tableWrapper {
  margin: 1.5rem 0 0;
  &_content {
    background: map-get($colors, foris-white) !important;
    padding: 0.5rem;
  }
}

.cntBtn {
  margin: 1.5rem 0 0;
  &_item {
    margin: 0 1.5rem 0 0;
    &:last-child {
      margin: 0;
    }
  }
}

.actions {
  &_btn {
    margin: 0 1.5rem 0 0;
    &:last-child {
      margin: 0;
    }
  }
}
