@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntSubjectList {
  &_title {
    @include text(16px, layout-grey-90, bold);
    padding: 0 0 1.5rem;
    .detail {
      font-weight: map-get($fonts, regular);
    }
  }
  .back {
    @include text(14px, layout-grey-50);
    padding: 0 0 0.5rem;
    cursor: pointer;
    &_icon {
      width: 16px;
      margin: 0 4px 0 0;
      color: map-get($colors, layout-grey-50);
    }
  }
  .cellNormal {
    @include text(14px, layout-grey-90);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  .cellLink {
    @include text(14px, darwined-blue-30);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  &_save {
    margin: 20px 0 0;
    .btn {
      margin: 0 0 0 14px;
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .labelCheckbox {
    padding: 0 0 0 12px;
    &_confirm {
      color: map-get($colors, message-positive-30);
      &_icon {
        width: 12px;
      }
    }
  }
  .cntSearchInput {
    padding: 0 0 10px 0;
  }
  .cntSearchInputLoader {
    padding-top: 10px;
    padding-left: 10px;
  }
  .cntPaginatorLoader {
    padding-top: 10px;
  }
}
