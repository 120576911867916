@import "../../../../assets/scss/variables";
@import "../../../../assets/scss//mixin";

.contextContent {
  padding: 16px;
  min-height: 150;
  text-align: center;
  &_text {
    @include text(14px, layout-grey-90);
    margin: 0;
    text-align: center;
  }
  &_btn {
    //TODO: eliminar '!important' cuando se reemplace <Button/> de forisUI por componente propio
    margin: 12px 0 0 !important;
  }
}
