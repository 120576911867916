@import "./variables";
@import "./mixin";

.react-tabs__tab {
  padding: 12px 14px !important;
  &.react-tabs__tab--selected {
    @include box(foris-white, 4px 4px 0 0, layout-grey-10);
    @include text(14px, layout-grey-90, bold);
    border-bottom: none;
  }
  &-list {
    margin: 0 !important;
    border-bottom: 1px solid map-get($colors, layout-grey-10) !important;
  }
  &-panel {
    &.react-tabs__tab-panel--selected {
      @include box(foris-white, 0 0 4px 4px, layout-grey-10);
      border-top: none;
    }
  }
}
