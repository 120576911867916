@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntHeaderDemand {
  @include box(foris-white, 0.25rem);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  .formDemand {
    $radiusForm: calc(0.25rem - 1px);
    @include box(layout-grey-5, $radiusForm $radiusForm 0 0);
    padding: 1rem;
    &_text {
      @include text(0.875rem, layout-grey-90);
      padding: 0 0 1.25rem;
    }
    .cntInputs {
      &_item {
        width: 60px;
        margin: 0 1.5rem 0 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .resumeDemand {
    min-width: 600px;
    padding: 1rem;
    .totalDeman {
      border-bottom: 1px solid map-get($colors, layout-grey-10);
      padding: 0 0 0.625rem 0;
      &:last-child {
        border-bottom: none;
        padding: 0.625rem 0 0;
      }
      &_label {
        @include text(0.875rem, layout-grey-90, bold);
      }
      &_item {
        @include text(0.875rem, layout-grey-90);
        .totalDeman_item__extra {
          color: map-get($colors, message-negative-50);
        }
        &.totalDeman_item__overloaded {
          color: map-get($colors, message-negative-30);
        }
        .icon {
          color: map-get($colors, layout-grey-90);
          margin: 0 0 0 0.5rem;
        }
      }
    }
  }
}
