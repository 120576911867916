@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntCrossList {
  &_title {
    @include text(16px, layout-grey-90, regular);
  }
  &_header {
    padding: 10px 0 10px 0;
  }
  .back {
    @include text(14px, layout-grey-50);
    cursor: pointer;
    &_icon {
      width: 16px;
      margin: 0 4px 0 0;
      color: map-get($colors, layout-grey-50);
    }
  }
  .check_icon {
    width: 14px;
    color: map-get($colors, layout-grey-50);
  }
  .cellNormal {
    @include text(14px, layout-grey-90);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  .cellLink {
    @include text(14px, darwined-blue-30);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  &_save {
    margin: 20px 0 0;
    .btn {
      margin: 0 0 0 14px;
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .labelCheckbox {
    padding: 0 0 0 12px;
    &.labelCheckbox__confirm {
      color: map-get($colors, message-positive-30);
      .icon {
        width: 12px;
      }
    }
  }

  .edit {
    @include text(14px, layout-grey-50);
    @include flex(flex-start, flex-start);
    margin: 0 0 0 8px;
    cursor: pointer;
    &_icon {
      width: 16px;
      margin: 0 4px 0 0;
      color: map-get($colors, layout-grey-50);
    }
  }
  .headerDemand {
    margin: 10px 0 20px;
  }
  .crosslistConfirmation {
    margin: 20px 0 0 0;
  }
  .cntSearchInput {
    padding: 0 0 10px 0;
  }
  .cntSearchInputLoader {
    padding: 10px 0 0 10px;
  }
  .cntPaginatorLoader {
    padding: 10px 0 0;
  }
}
