@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.rc-pagination-options {
  display: none;
}

.rc-pagination-prev,
.rc-pagination-item,
.rc-pagination-next {
  border: none;
  border-radius: 0;
  a {
    color: map-get($colors, layout-grey-30);
  }
}

.rc-pagination {
  li {
    border-radius: 0;
    margin: 0;
    &:focus {
      outline: none;
    }
  }
}
.rc-pagination-prev,
.rc-pagination-item,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  background: map-get($colors, foris-white);
  border: solid map-get($colors, layout-grey-30);
  border-width: 1px 0 1px 1px;
  &.rc-pagination-item-active {
    background: map-get($colors, layout-grey-10);
    a,
    button {
      color: map-get($colors, layout-grey-50);
    }
  }
}
.rc-pagination-next {
  background: map-get($colors, foris-white);
  border: 1px solid map-get($colors, layout-grey-30);
}
.ui.menu {
  box-shadow: none !important;
  border: none !important;
  .item {
    color: map-get($colors, layout-grey-30) !important;
  }
}
