@import "../../../../assets/scss/variables";
@import "../../../../assets/scss//mixin";

.context {
  flex: 1;
  max-width: 412px;
  border-left: 1px solid map-get($colors, neutral-10);
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0 0.75rem;
  align-items: center;
  margin: 0 0 0 0.75rem;

  &:hover {
    background-color: map-get($colors, layout-grey-5);
    color: map-get($colors, layout-grey-90);
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &_label {
      font-size: 10px;
      line-height: 12px;
      color: map-get($colors, neutral-50);
      font-weight: 500;
      margin: 0 0 0.25rem;
    }

    &_info {
      font-size: 12px;
      line-height: 16px;
      color: #465669;
      font-weight: 500;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.context_content {
  padding: 16px;
  min-height: 200px;
  .context_select {
    @include flex(space-between);
    min-height: 200px;
    padding: 16px 0 16px 10px;
    .input_select {
      padding: 10px 0;
      margin-left: 10px;
      width: 100%;
      height: 100%;
      min-width: 180px;
      @include text(0.875rem, layout-grey-50);
      label {
        margin-bottom: 10px;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    .btn_footer_primary {
      width: 138px;
      padding: 10px;
      background: map-get($colors, darwined-blue-50);
      border-radius: 5px;
      color: map-get($colors, foris-white);
      border: none;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background: map-get($colors, darwined-blue-70);
      }
      &.disabled {
        background: map-get($colors, layout-grey-50);
      }
    }
    .btn_left_footer_primary {
      width: 138px;
      padding: 10px;
      margin-right: auto;
    }
  }
}
