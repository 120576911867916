@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.accordion {
  border-bottom: 1px solid map-get($colors, layout-grey-10);
  &:last-of-type {
    border-bottom: none;
  }
  .header {
    &_item {
      padding: 12px 20px;
      cursor: pointer;
      outline: none;
      .icon {
        width: 16px;
        color: map-get($colors, layout-grey-30);
        transition: 0.5s ease;
        &.icon__active {
          transform: rotate(-180deg);
          transition: 0.5s ease;
        }
      }
    }
  }
  .content {
    width: 100%;
  }
}
