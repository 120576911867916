@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.layoutContainer {
  margin: 0 auto;

  .link {
    display: "inline-block";
    &__button {
      background-color: map-get($colors, layout-white);
      margin-bottom: 1rem;
    }
  }
}
