@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.cntProgress {
  @include box(layout-grey-10, 4px, layout-grey-30);
  position: relative;
  min-width: 100px;
  height: 16px;
  &_percent {
    $border: 3px 0 0 3px;
    @include box(message-alert-30, $border);
    height: 14px;
    max-width: 95%;
    position: absolute;
    top: 0;
    left: 0;
    &.cntProgress_percent__full {
      $border: 3px;
      @include box(foris-green-50, $border);
      max-width: 100%;
    }
  }
}
