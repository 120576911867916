@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixin";

.cntItemsLevel {
  &_status {
    @include box(message-alert-30, 50%, layout-grey-30);
    width: 16px;
    height: 16px;
    margin: 0 12px 0 0;
    &.cntItemsLevel_status__empty {
      background: map-get($colors, layout-grey-10);
    }
    &.cntItemsLevel_status__full {
      background: map-get($colors, foris-green-50);
    }
  }
  .title {
    @include text(16px, layout-grey-90, medium);
    text-transform: uppercase;
    margin: 0;
    &_detail {
      @include text(16px, layout-grey-90);
      &.title_detail__lower {
        text-transform: capitalize;
      }
    }
  }
  .cntItemsProgressbar {
    margin: 0;
    padding: 12px 20px;
    background: map-get($colors, layout-grey-5);
    border-bottom: 1px solid map-get($colors, layout-grey-30);
    &:last-of-type {
      border-bottom: none;
    }
    &_title {
      @include text(16px, layout-grey-90, medium);
      margin: 0;
    }
    &_item {
      min-width: 108px;
      margin: 16px 24px 0 0;
      display: flex;
      align-items: center;
      &:last-of-type {
        margin: 16px 0 0;
      }
      .cntLevelProgress {
        display: flex;
        align-items: center;
        &_level {
          @include text(14px, layout-grey-90, medium);
          margin: 0 4px 0 0;
        }
      }
    }
  }
}
.textEmpty {
  @include text(14px, layout-grey-70, medium);
  margin: 0;
}
