@import "./../icomoon/variables";

.react-datepicker {
  &__input-container {
    padding: 0 !important;
    &::after {
      top: 12px !important;
    }
  }
}
