@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntLevel {
  .title {
    @include text(16px, layout-grey-90, bold);
    padding: 0 0 1.5rem;
    text-transform: uppercase;
    &_detail {
      font-weight: map-get($fonts, regular);
      &.title_detail__lower {
        text-transform: capitalize;
      }
    }
  }
  .back {
    @include text(14px, layout-grey-50);
    padding: 0 0 0.5rem;
    cursor: pointer;
    &_icon {
      width: 16px;
      margin: 0 4px 0 0;
      color: map-get($colors, layout-grey-50);
    }
  }
  .cellNormal {
    @include text(14px, layout-grey-90);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  .cellLink {
    @include text(14px, darwined-blue-30);
    text-transform: uppercase;
    &_bold {
      font-weight: map-get($fonts, bold);
    }
  }
  &_save {
    margin: 20px 0 0;
    .btn {
      margin: 0 0 0 14px;
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .labelCheckbox {
    padding: 0 0 0 12px;
    &_confirm {
      color: map-get($colors, message-positive-30);
      &_icon {
        width: 12px;
      }
    }
  }

  .cntNav {
    margin: 0 0 6px;
    &_title {
      @include text(16px, layout-grey-90, medium);
      padding: 0 8px 0 0;
      margin: 0;
    }
    .buttonsNav {
      @include box(foris-white, 4px, layout-grey-30);
      width: 72px;
      height: 36px;
      .button {
        width: 50%;
        height: 100%;
        background: none;
        padding: 0;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 0 3px 3px 0;
        &:first-of-type {
          border-right: 1px solid map-get($colors, layout-grey-30);
          border-radius: 3px 0 0 3px;
        }
        &_icon {
          width: 10px;
          color: map-get($colors, layout-grey-30);
        }
        &:disabled {
          background: map-get($colors, layout-grey-10);
          cursor: no-drop;
          .button_icon {
            color: map-get($colors, layout-grey-30);
          }
        }
      }
    }
  }

  .cntSearchInput {
    padding-top: 20px;
    padding-left: 10px;
  }
  .cntSearchInputLoader {
    padding-top: 30px;
    padding-left: 10px;
  }
  .cntPaginatorLoader {
    padding-top: 10px;
  }
}
