@import "../../../../assets/scss/variables";
@import "../../../../assets/scss//mixin";

.menuProducts {
  padding: 0 !important;
  &_icon {
    color: map-get($colors, neutral-50);
    width: 20px;
  }
  .cntProducts {
    min-width: 180px;
    max-width: 500px;
    padding: 20px;
    align-items: flex-start;
    border-bottom: 1px solid map-get($colors, layout-grey-10);
    &_title {
      @include text(14px, layout-grey-90, bold);
      padding: 0 0 0.875rem;
    }
    &:last-child {
      border-bottom: none;
    }
    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100px;
      margin: 0 20px 0 0;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
      .logo {
        @include box(layout-grey-5, 50%, layout-grey-10);
        width: 50px;
        height: 50px;
        padding: 8px;
        &.logo__active,
        &:hover {
          background: map-get($colors, darwined-blue-50);
          border-color: transparent;
        }
      }
      .nameProduct {
        @include text(14px, darwined-blue-30, bold);
        padding: 10px 0 0;
        text-align: center;
      }
    }
  }
}
