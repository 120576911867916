.dashboardHeader {
  display: flex;
  width: 100%;

  .cardContent {
    width: 100%;
    &_selectors {
      display: flex;
      flex-direction: row;
      padding: 1rem 0;
    }
  }
}
