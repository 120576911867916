@import "../../../assets/scss/mixin";
@import "../../../assets/scss/variables";

.cntTable {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  .cntHeader {
    @include box(darwined-blue-30, 4px 4px 0 0, darwined-blue-30);
    .headerList {
      height: 50px;
      margin: 0;
      padding: 0;
      list-style: none;
      &_item {
        @include text(1rem, foris-white, bold);
        flex: 1;
        align-self: center;
        padding: 0 12px;
      }
    }
  }
  .contentTable {
    @include box(foris-white, 0 0 4px 4px, layout-grey-10);
    .contentList {
      min-height: 50px;
      margin: 0;
      padding: 0.25rem 0;
      border-bottom: 1px solid map-get($colors, layout-grey-10);
      list-style: none;
      &:last-of-type {
        border-bottom: 0;
      }
      &_item {
        @include text(14px, layout-grey-90);
        position: relative;
        flex: 1;
        align-self: center;
        padding: 0 12px;
        .inputCell {
          margin: 0 0 0 -12px;
        }
      }
    }
  }
}

.checkboxTable[type="checkbox"] {
  @include box(foris-white, 2px, layout-grey-30);
  height: 18px;
  width: 18px;
  cursor: pointer;
  appearance: none;
  outline: 0;
}

.checkboxTable[type="checkbox"]:checked {
  background: map-get($colors, darwined-blue-50);
  border: 1px solid map-get($colors, darwined-blue-50);
}

.checkboxTable[type="checkbox"]:hover {
  filter: brightness(90%);
}

.checkboxTable[type="checkbox"]:disabled {
  background: map-get($colors, layout-grey-10);
  border: 1px solid map-get($colors, layout-grey-30);
  pointer-events: none;
}

.checkboxTable[type="checkbox"]:after {
  content: "";
  width: 4px;
  height: 10px;
  display: none;
  position: relative;
  left: 40%;
  top: 20%;
  border: solid map-get($colors, foris-white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxTable[type="checkbox"]:checked:after {
  display: block;
}

.checkboxTable[type="checkbox"]:disabled:after {
  border-color: map-get($colors, layout-grey-70);
}
