@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.cnt_search {
  @include box(foris-white, 0.25rem, layout-grey-30);
  padding: 0;
  position: relative;

  &-input {
    flex: 1;
    position: relative;
    padding: 0.5rem 3.25rem 0.5rem 0.75rem;
    border: none;
    outline: none;
    .input_search {
      color: (14, layout-grey-70);
      padding: 0;
      border: none;
      outline: none;
      width: 100%;
      &::placeholder {
        color: (14, layout-grey-30);
      }
    }
  }
  &-btn {
    @include box(uem-blue-50, 0.25rem);
    background-color: map-get($colors, darwined-blue-30);
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    width: 40px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    .icon_search {
      width: 14px;
      color: map-get($colors, foris-white);
      margin: 1px 0 0;
    }
  }
}
