@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixin";

.cntCrossList {
  padding: 24px;
  &_filter {
    margin: 0 0 20px;
  }
  &_textEmpty {
    @include text(14px, layout-grey-70, medium);
    margin: 0;
  }
  .cntTable {
    .cntHeaderList {
      list-style: none;
      margin: 0;
      padding: 0;
      &_item {
        @include text(14px, layout-grey-90, bold);
        padding: 8px;
        border-right: 1px solid map-get($colors, layout-grey-30);
        flex: 1;
        &:last-of-type {
          border-right: 0;
        }
        &:nth-last-child(2),
        &:last-of-type {
          max-width: 120px;
        }
        &.cntHeaderList_item__progressBar {
          flex-grow: 1;
          min-width: 180px;
          max-width: 250px;
        }
      }
    }
    .cntRowList {
      @extend .cntHeaderList;
      border-top: 1px solid map-get($colors, layout-grey-30);
      &:last-of-type {
        border-bottom: 1px solid map-get($colors, layout-grey-30);
      }
      &_item {
        @extend .cntHeaderList_item;
        font-weight: map-get($map: $fonts, $key: regular);
        word-break: break-word;
        cursor: pointer;
        &.cntRowList_item__progressBar {
          @extend .cntHeaderList_item__progressBar;
        }
        &__icon {
          width: 12px;
        }
      }
    }
  }
}
