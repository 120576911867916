@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixin";

.cntInput {
  position: relative;
  &_label {
    @include text(12px, layout-grey-90, bold);
    display: block;
    margin: 0 0 4px;
  }
  &_item {
    @include text(14px, layout-grey-70);
    @include box(foris-white, 4px, layout-grey-10);
    width: 100%;
    flex-grow: 1;
    padding: 10px 12px;
    outline: none;
    &::placeholder {
      color: map-get($colors, layout-grey-30);
    }
    &:focus {
      outline: none;
      border: 1px solid map-get($colors, darwined-blue-30);
      color: map-get($colors, layout-grey-50);
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &_item:focus + &_icon {
    color: map-get($colors, layout-grey-50);
  }
  &_icon {
    width: 16px;
    position: absolute;
    top: 12px;
    right: 8px;
    font-size: 15px;
    color: map-get($colors, layout-grey-30);
  }
}
